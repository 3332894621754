import { gql } from '@apollo/client';

export const locationUpdates = gql`
    query locationUpdates($locationId: InternalId!) {
        locationUpdates(locationId: $locationId) {
            _id
            image {
                url
            }
            description
            displayInClinics
        }
    }
`;

export const locationTimezone = gql`
    query locationTimezone($locationId: ID!) {
        location(locationId: $locationId) {
            timezone
        }
    }
`;

export const npsScores = gql`
    query npsScores($locationId: InternalId!) {
        surveyResults(locationId: $locationId, numberOfReviews: 10) {
            _id
            appointment {
                firstName
            }
            score
            date
        }
    }
`;

export const googleReviews = gql`
    query googleReviews($locationId: InternalId!) {
        googleReviews(locationId: $locationId, limit: 8) {
            reviews {
                _id
                reviewer {
                    displayName
                }
                date
                comment
                rating
            }
            averageRating
        }
    }
`;

export const impactScoreV2 = gql`
    query impactScoreV2($locationId: InternalId, $date: DateString) {
        impactScoreV2(
            locationId: $locationId
            date: $date
            application: "TV Screens"
        ) {
            locationId
            vetspireLocationId
            date
            visits
            visitsMilestone1
            visitsMilestone2
            visits100PercentValue
            visitsPercent
            visitsMilestone1Percent
            visitsMilestone2Percent
            revenuePerDoctor
            revenueMilestone1
            revenuePerDoctorMilestone1
            revenuePerDoctorMilestone2
            revenuePerDoctorPercent
            revenuePerDoctorMilestone1Percent
            revenuePerDoctorMilestone2Percent
            revenuePerDoctor100PercentValue
            payoutMilestone1
            payoutMilestone2
            milestone1Reached
            milestone2Reached
            payout
            payoutOfMonth
            bonusPercentMilestone1
            bonusPercentMilestone2
        }
    }
`;
